import React from "react"

import Layout from "components/Layout"

import SubpageHero from "components/SubpageHero"
import Breadcrumbs from "components/Breadcrumbs"
import SubpageBack from "components/SubpageBack"

import { Content } from "page_components/contact"
import { Contact } from "page_components/home"

const ContactPage = ({ location }) => (
  <Layout
    location={location}
    seo={{
      title: "Kontakt",
    }}
  >
    <SubpageHero title="Kontakt" />
    <Breadcrumbs title="Kontakt" />
    <Content />
    <Contact />
    <SubpageBack />
  </Layout>
)

export default ContactPage
